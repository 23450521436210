import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChartPieIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

const DashboardHeader = () => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h2 className="text-2xl font-semibold leading-7 text-slate-900 dark:text-white dark:text-white dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">
          Console
        </h2>
      </div>
      <div className="mt-4 sm:ml-16 sm:mt-0 flex gap-2">
        {/* Filters dropdown */}
        <Menu as="div" className="relative ml-2 flex-shrink-0">
          <div>
            <MenuButton className="group inline-flex items-center rounded-md bg-white text-base font-normal text-slate-900 hover:text-slate-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-slate-900 dark:text-white dark:text-white dark:text-white dark:text-white dark:focus:ring-offset-slate-900">
              <span className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-500 dark:hover:bg-indigo-400 dark:focus:ring-offset-slate-900">
                <ChartPieIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                Report
              </span>
            </MenuButton>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="stransition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-800 ">
              <MenuItem>
                {({ active }) => (
                  <Link
                    to="/dashboard"
                    className={clsx(
                      active ? "bg-slate-100 dark:bg-slate-900" : "",
                      "block px-4 py-2 text-sm text-slate-600 dark:text-slate-400  dark:hover:text-white"
                    )}
                  >
                    Day
                  </Link>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <Link
                    to="/dashboard"
                    className={clsx(
                      active ? "bg-slate-100 dark:bg-slate-900" : "",
                      "block px-4 py-2 text-sm text-slate-600 dark:text-slate-400  dark:hover:text-white"
                    )}
                  >
                    Week
                  </Link>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <Link
                    to="/dashboard"
                    className={clsx(
                      active ? "bg-slate-100 dark:bg-slate-900" : "",
                      "block px-4 py-2 text-sm text-slate-600 dark:text-slate-400  dark:hover:text-white"
                    )}
                  >
                    Month
                  </Link>
                )}
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>

        <Link
          to="/logout"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-500 dark:hover:bg-indigo-400 dark:focus:ring-offset-slate-900"
        >
          Logout
        </Link>
      </div>
    </div>
  );
};

export default DashboardHeader;
