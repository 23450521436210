import { ReactNode } from "react";

type AvatarProps = {
  color?: string;
  picture?: string;
  pictureFallback?: ReactNode;
  size?: number;
};

export default function Avatar({
  color = "slate",
  size = 12,
  picture = "",
  pictureFallback,
}: AvatarProps) {
  return (
    <div
      className={`h-${size} w-${size} overflow-hidden rounded-full bg-${color}-50 dark:bg-${color}-700/20 flex-shrink-0`}
    >
      {picture ? (
        <img
          width={size}
          height={size}
          className={`inline-block h-${size} w-${size} rounded-full object-cover`}
          src={picture}
          alt=""
        />
      ) : (
        <>
          {pictureFallback ? (
            pictureFallback
          ) : (
            <svg
              className={`h-full w-full text-${color}-500 dark:text-${color}-400`}
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
        </>
      )}
    </div>
  );
}
