import { randImgUrl } from "@/utils/randImgUrl";
import LoginForm from "@/components/loginForm";
import { Link } from "react-router-dom";

const imgUrl = randImgUrl();

const Login = () => {
  return (
    <div className="relative flex lg:h-screen">
      <div className="flex flex-1 flex-col items-center justify-center  px-4 py-12 sm:px-6 lg:h-screen lg:flex-none lg:overflow-y-auto  lg:px-24 ">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <Link to="/">
              <img
                className="h-8 w-auto dark:hidden"
                src="/assets/img/logo-slate-900.svg"
                alt="Koosh"
              />
              <img
                className="hidden h-8 w-auto dark:block"
                src="/assets/img/logo-white.svg"
                alt="Koosh"
              />
            </Link>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-slate-900 dark:text-white">
              Sign in
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden w-0 flex-1 bg-slate-50 dark:bg-slate-800 lg:block">
        <img
          className="mx-auto h-full w-full object-cover"
          src={imgUrl}
          alt=""
        />
      </div>
    </div>
  );
};

export default Login;
