import { useEffect } from "react";
import { getUserLogout } from "@/api";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getUserLogout();
      navigate("/login");
    })();
  }, [navigate]);

  return (
    <div>
      <h1>Logout</h1>
    </div>
  );
};

export default Logout;
