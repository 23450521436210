"use client";

import clsx from "clsx";
import SortButton from "../sortButton";
import Input from "../input/input";
import Selectbox from "../selectbox";
import DatePickerRange from "../datePickerRange";
import { useCallback, useEffect, useRef } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import { TIAMUser } from "../../types/app";
import { TIAMUserRequest } from "../../api";

const filterVerificationOptions = [
  {
    title: "All",
    description: "",
    value: "",
    current: true,
  },
  {
    title: "Verified",
    description: "",
    value: "true",
    current: false,
  },
  {
    title: "Not",
    description: "",
    value: "false",
    current: false,
  },
];

const filterSubscriptionOptions = [
  {
    title: "All",
    description: "",
    value: "",
    current: true,
  },
  {
    title: "Subsribed",
    description: "",
    value: "true",
    current: false,
  },
  {
    title: "Not",
    description: "",
    value: "false",
    current: false,
  },
];

export type DashboardTableHeaderProps = {
  sortField: keyof TIAMUser;
  sortDirection: "asc" | "desc";
  isIndeterminate: boolean;
  checked: boolean;
  onFilterUpdate: (filter: TIAMUserRequest) => void;
  onSortChange: (field: keyof TIAMUser, direction: "asc" | "desc") => void;
  onToggleAll: () => void;
};

const DashboardTableHeader = ({
  sortField,
  sortDirection,
  isIndeterminate,
  checked,
  onFilterUpdate,
  onSortChange,
  onToggleAll,
}: DashboardTableHeaderProps) => {
  const checkbox: any = useRef();

  useEffect(() => {
    checkbox.current.indeterminate = isIndeterminate;
  }, [isIndeterminate]);

  const getDirection = (field: keyof TIAMUser) => {
    if (field === sortField) {
      return sortDirection;
    }
    return "asc";
  };

  const onFirstNameFilterChange = useCallback(
    (value: string) => {
      onFilterUpdate({ firstName: value });
    },
    [onFilterUpdate]
  );

  const onLastNameFilterChange = useCallback(
    (value: string) => {
      onFilterUpdate({ lastName: value });
    },
    [onFilterUpdate]
  );

  const onEmailFilterChange = useCallback(
    (value: string) => {
      onFilterUpdate({ email: value });
    },
    [onFilterUpdate]
  );

  const onSubscribedFilterChange = useCallback(
    (value: string) => {
      onFilterUpdate({ subscribed: value });
    },
    [onFilterUpdate]
  );

  const onVerifiedFilterChange = useCallback(
    (value: string) => {
      onFilterUpdate({ verified: value });
    },
    [onFilterUpdate]
  );

  const onExpirationFilterChange = useCallback(
    (value: DateValueType) => {
      if (value?.startDate && value?.endDate) {
        onFilterUpdate({
          subscription_expired_at_from: value.startDate?.toString(),
          subscription_expired_at_to: value.endDate?.toString(),
        });
      } else {
        onFilterUpdate({
          subscription_expired_at_from: "",
          subscription_expired_at_to: "",
        });
      }
    },
    [onFilterUpdate]
  );

  const onRegisteredFilterChange = useCallback(
    (value: DateValueType) => {
      if (value?.startDate && value?.endDate) {
        onFilterUpdate({
          created_at_from: value.startDate?.toString(),
          created_at_to: value.endDate?.toString(),
        });
      } else {
        onFilterUpdate({
          created_at_from: "",
          created_at_to: "",
        });
      }
    },
    [onFilterUpdate]
  );

  return (
    <thead>
      <tr>
        <th scope="col" className="relative px-7 sm:w-12 sm:px-6"></th>

        <th
          scope="col"
          className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("firstName")}
            className={clsx({
              underline: sortField === "firstName",
            })}
            onDirectionChange={(direction) =>
              onSortChange("firstName", direction)
            }
          >
            First Name
          </SortButton>
        </th>

        <th
          scope="col"
          className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("lastName")}
            className={clsx({
              underline: sortField === "lastName",
            })}
            onDirectionChange={(direction) =>
              onSortChange("lastName", direction)
            }
          >
            Last Name
          </SortButton>
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("email")}
            className={clsx({
              underline: sortField === "email",
            })}
            onDirectionChange={(direction) => onSortChange("email", direction)}
          >
            Email
          </SortButton>
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("verified")}
            className={clsx({
              underline: sortField === "verified",
            })}
            onDirectionChange={(direction) =>
              onSortChange("verified", direction)
            }
          >
            Verified
          </SortButton>
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("subscribed")}
            className={clsx({
              underline: sortField === "subscribed",
            })}
            onDirectionChange={(direction) =>
              onSortChange("subscribed", direction)
            }
          >
            Subscribed
          </SortButton>
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("subscription_expired_at")}
            className={clsx({
              underline: sortField === "subscription_expired_at",
            })}
            onDirectionChange={(direction) =>
              onSortChange("subscription_expired_at", direction)
            }
          >
            Expiration
          </SortButton>
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <SortButton
            direction={getDirection("created_at")}
            className={clsx({
              underline: sortField === "created_at",
            })}
            onDirectionChange={(direction) =>
              onSortChange("created_at", direction)
            }
          >
            Registered
          </SortButton>
        </th>

        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
      <span className="sr-only">Edit</span>
    </th> */}
      </tr>

      <tr>
        <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-600"
            ref={checkbox}
            checked={checked}
            onChange={onToggleAll}
          />
        </th>

        <th
          scope="col"
          className="min-w-[12rem] pr-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <Input
            type="text"
            placeholder="First name"
            className="w-full"
            onChange={onFirstNameFilterChange}
          />
        </th>

        <th
          scope="col"
          className="min-w-[12rem] pr-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <Input
            type="text"
            placeholder="Last name"
            className="w-full"
            onChange={onLastNameFilterChange}
          />
        </th>

        <th
          scope="col"
          className="px-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <Input
            type="text"
            placeholder="Email"
            className="w-full"
            onChange={onEmailFilterChange}
          />
        </th>

        <th
          scope="col"
          className="px-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <Selectbox
            options={filterVerificationOptions}
            onChange={onVerifiedFilterChange}
            value={filterVerificationOptions[0].value}
          />
        </th>

        <th
          scope="col"
          className="px-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <Selectbox
            options={filterSubscriptionOptions}
            onChange={onSubscribedFilterChange}
            value={filterSubscriptionOptions[0].value}
          />
        </th>

        <th
          scope="col"
          className="px-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <DatePickerRange onChange={onExpirationFilterChange} />
        </th>

        <th
          scope="col"
          className="px-3 text-left text-sm font-semibold text-slate-900 dark:text-white"
        >
          <DatePickerRange onChange={onRegisteredFilterChange} />
        </th>
      </tr>
    </thead>
  );
};

export default DashboardTableHeader;
