import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { TDirection } from "@/types/app";

type SortButtonProps = {
  direction: TDirection;
  className?: string;
  onDirectionChange: (direction: TDirection) => void;
};

const SortButton = ({
  direction,
  children,
  className,
  onDirectionChange,
}: React.PropsWithChildren<SortButtonProps>) => {
  return (
    <div
      className={clsx("group inline-flex cursor-pointer", className)}
      onClick={() => onDirectionChange(direction === "asc" ? "desc" : "asc")}
    >
      {children}
      <span className="ml-2 flex-none rounded bg-slate-100 text-slate-900 dark:bg-slate-800 dark:text-white  dark:group-hover:bg-slate-700">
        {direction === "desc" ? (
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </span>
    </div>
  );
};

export default SortButton;
