import { ButtonHTMLAttributes } from "react";
import { COLORS } from "@/types/app";
import clsx from "clsx";

type ButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  isLoading?: boolean;
  label: string;
  color?: COLORS;
  isDisabled?: boolean;
  className?: string;
  loadingFill?: string;
  customActiveClasses?: string;
  customDisabledOrLoadingClasses?: string;
  classNameRewrite?: string;
};

export const Button = ({
  onClick,
  type,
  isLoading,
  label,
  color = "indigo",
  isDisabled,
  className,
  customActiveClasses,
  customDisabledOrLoadingClasses,
  classNameRewrite,
  loadingFill = "white",
}: ButtonProps) => {
  const activeClasses = customActiveClasses
    ? customActiveClasses
    : `bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2 dark:bg-${color}-500 dark:hover:bg-${color}-400 dark:focus:ring-offset-slate-900`;
  const disabledOrLoadingClasses = customDisabledOrLoadingClasses
    ? customDisabledOrLoadingClasses
    : `cursor-not-allowed bg-${color}-500 hover:bg-${color}-400 dark:bg-${color}-300`;

  return (
    <button
      type={type ?? "submit"}
      className={clsx(
        classNameRewrite
          ? classNameRewrite
          : "inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-normal text-white shadow-sm",
        isLoading || isDisabled ? disabledOrLoadingClasses : activeClasses,
        className
      )}
      onClick={onClick}
    >
      {isLoading && (
        <svg
          className={`-ml-1 mr-3 h-5 w-5 animate-spin text-${loadingFill}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx={12}
            cy={12}
            r={10}
            stroke="currentColor"
            strokeWidth={4}
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {label}
    </button>
  );
};
