type DashboardTableFooterProps = {
  totalSubscribed: number;
  totalVerified: number;
  total: number;
};

const DashboardTableFooter = ({
  totalSubscribed,
  totalVerified,
  total,
}: DashboardTableFooterProps) => {
  return (
    <tfoot>
      <tr>
        <th
          scope="row"
          colSpan={6}
          className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-slate-600 dark:text-slate-400 sm:table-cell sm:pl-0"
        >
          Subscribed:
        </th>
        <td className="pl-3 pr-4 pt-6 text-left text-sm text-slate-600 dark:text-slate-400 sm:pr-0">
          {totalSubscribed}
        </td>
      </tr>
      <tr>
        <th
          scope="row"
          colSpan={6}
          className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-slate-600 dark:text-slate-400 sm:table-cell sm:pl-0"
        >
          Verified:
        </th>
        <td className="pl-3 pr-4 pt-4 text-left text-sm text-slate-600 dark:text-slate-400 sm:pr-0">
          {totalVerified}
        </td>
      </tr>
      <tr>
        <th
          scope="row"
          colSpan={6}
          className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-slate-900 dark:text-white sm:table-cell sm:pl-0"
        >
          Total:
        </th>
        <td className="pl-3 pr-4 pt-4 text-left text-sm font-semibold text-slate-900 dark:text-white sm:pr-0">
          {total}
        </td>
      </tr>
    </tfoot>
  );
};

export default DashboardTableFooter;
