import React, { useEffect, useState } from "react";

const highlights = [
  "As of 2022, an average person is predicted to spend 100 minutes per day watching online videos.",
  "According to Brightcove, the video gives a 157% organic traffic increase from search engine results pages.",
  "YouTube currently has a fascinating number of 2+ billion users. It can be roughly described as almost ⅓ of the Internet.",
];

export default function Highlights() {
  const [tip, setTip] = useState("");

  useEffect(() => {
    const min: number = 0;
    const max: number = highlights.length - 1;
    let timer: ReturnType<typeof setTimeout>;

    (function getText() {
      setTip(highlights[getRandomInt(min, max)]);

      timer = setTimeout(function () {
        getText();
      }, 3000);
    })();

    function getRandomInt(min: number, max: number) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function stop() {
      if (timer) {
        clearTimeout(timer);
      }
    }

    return () => stop();
  }, []);

  return <>{tip}</>;
}
