import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/button";
import { ShowHidePasswordIcon } from "@/components/showHidePassword";
import { UserInfoContext } from "@/contexts/userInfoContext";
import { postUserLogin } from "@/api";
import { ToastContext } from "../contexts/toastContext";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

enum INPUTS {
  API = "api",
  EMAIL = "input-email",
  PASSWORD = "input-password",
}

const errorClasses =
  "border-red-300 placeholder-red-300 text-red-900 focus:border-red-500 focus:ring-red-500 dark:focus:ring-offset-slate-900 dark:bg-slate-800 dark:border-slate-800 dark:text-red-500";
const validClasses =
  "border-slate-300  dark:bg-slate-800 dark:border-slate-800 dark:text-white focus:border-indigo-500 focus:ring-indigo-500 dark:focus:ring-offset-slate-900 focus:dark:border-indigo-500 ";

const LoginForm: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [error, setError] = useState<Partial<Record<INPUTS, string>>>({});
  const { refetchUserInfo } = useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const isDisabled =
    Object.values(error).some((er) => !!er) || !email || !password;

  const handleLoginClick = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDisabled) return;

    try {
      setIsLoading(true);
      await postUserLogin({ email, password });
      await refetchUserInfo();
      navigate("/dashboard");
    } catch (err: any) {
      setError({ [INPUTS.API]: err.detail });
      showToast(
        { title: "Failed to login", subtitle: "Invalid username or password" },
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setError((error) => ({ ...error, [INPUTS.API]: "" }));

    switch (event.target.id as INPUTS) {
      case INPUTS.EMAIL: {
        setEmail(value);
        if (!value) {
          setError((error) => ({ ...error, [INPUTS.EMAIL]: "Required" }));
        } else if (error[INPUTS.EMAIL]) {
          setError((error) => ({ ...error, [INPUTS.EMAIL]: "" }));
        }
        break;
      }
      case INPUTS.PASSWORD: {
        setPassword(value);
        if (!value) {
          setError((error) => ({ ...error, [INPUTS.PASSWORD]: "Required" }));
        } else if (error[INPUTS.PASSWORD]) {
          setError((error) => ({ ...error, [INPUTS.PASSWORD]: "" }));
        }

        break;
      }
    }
  };

  return (
    <form onSubmit={handleLoginClick} className="space-y-6">
      <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label
            htmlFor="email"
            className="block text-sm font-normal text-slate-600 dark:text-slate-400"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="input-email"
              name="email"
              type="email"
              autoComplete="current-email"
              required
              onChange={handleInputChange}
              value={email}
              className={classNames(
                error[INPUTS.EMAIL] ? errorClasses : validClasses,
                "block w-full rounded-md pr-10 shadow-sm focus:outline-none sm:text-sm"
              )}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="password"
            className="block text-sm font-normal text-slate-600 dark:text-slate-400"
          >
            Password
          </label>
          <div className="relative mt-1">
            <input
              type={isPasswordShown ? "text" : "password"}
              id="input-password"
              name="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={handleInputChange}
              className={classNames(
                error[INPUTS.PASSWORD] ? errorClasses : validClasses,
                "block w-full rounded-md pr-10 shadow-sm focus:outline-none sm:text-sm"
              )}
            />
            <ShowHidePasswordIcon
              onClick={() => setIsPasswordShown((s) => !s)}
              isShown={isPasswordShown}
            />
          </div>
        </div>
      </div>

      <div>
        <Button
          type="submit"
          onClick={handleLoginClick}
          className="w-full"
          label="Sign in"
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default LoginForm;
