import { useState } from "react";

export type SelectboxOption = {
  title: string;
  description: string;
  value: string;
  current: boolean;
};

type SelectboxProps = {
  options: SelectboxOption[];
  value?: SelectboxOption["value"];
  title?: string;
  description?: string;
  placeholder?: string;
  onChange: (option: SelectboxOption["value"]) => void;
};

const Selectbox = ({
  options,
  value,
  title,
  description,
  placeholder,
  onChange,
}: SelectboxProps) => {
  const [currentValue, setCurrentValue] = useState(() =>
    options.find((option) => option.value === value)
  );

  const onListboxChange = (value: any) => {
    const option: SelectboxOption | undefined = options.find(
      (option) => option.value === value
    );
    if (option) {
      setCurrentValue(option);
      onChange(option?.value);
    }
  };

  return (
    <div className="mb-6 space-y-8 ">
      <select
        id="product-cta"
        name="product-cta"
        onChange={(e: any) => onListboxChange(e.target.value)}
        value={currentValue?.value}
        className="mt-1 block w-full cursor-pointer rounded-md border-0 text-slate-900 ring-1 ring-inset ring-slate-300 focus:ring-2 focus:ring-indigo-600  dark:bg-slate-800 dark:text-white dark:ring-0 dark:ring-white/10 dark:focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selectbox;
