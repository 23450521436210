import React, { useMemo, useRef, useState } from "react";
import { SuccessToast } from "@/components/successToast";
import { ErrorToast } from "@/components/errorToast";
import { InfoToast } from "@/components/infoToast";

type ShowToastProps = {
  subtitle?: string;
  title?: string;
};

export type ToastContextType = {
  showToast: (
    props?: ShowToastProps,
    type?: "success" | "info" | "error"
  ) => void;
};

const defaultState: ToastContextType = {
  showToast: () => {},
};

const TOAST_SUCCESS_TIMEOUT = 2000;
const TOAST_ERROR_TIMEOUT = 10000;
const TOAST_INFO_TIMEOUT = 5000;

export const ToastContext = React.createContext(defaultState);

const ToastProvider = ({ children }: React.PropsWithChildren) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const title = useRef("");
  const subtitle = useRef("");

  const state = useMemo<ToastContextType>(
    () => ({
      showToast: (props, type = "success") => {
        title.current = props?.title ?? "";
        subtitle.current = props?.subtitle ?? "";

        switch (type) {
          case "success":
            setShowSuccess(true);
            setTimeout(() => {
              setShowSuccess(false);
            }, TOAST_SUCCESS_TIMEOUT);
            break;
          case "error":
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, TOAST_ERROR_TIMEOUT);
            break;
          case "info":
            setShowInfo(true);
            setTimeout(() => {
              setShowInfo(false);
            }, TOAST_INFO_TIMEOUT);
            break;
        }
      },
    }),
    []
  );

  return (
    <ToastContext.Provider value={state}>
      {children}
      <SuccessToast
        show={showSuccess}
        setShow={setShowSuccess}
        subtitle={subtitle.current}
        title={title.current}
      />
      <ErrorToast
        show={showError}
        setShow={setShowError}
        subtitle={subtitle.current}
        title={title.current}
      />
      <InfoToast
        show={showInfo}
        setShow={setShowInfo}
        subtitle={subtitle.current}
        title={title.current}
      />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
