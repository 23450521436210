import clsx from "clsx";
import React, { useState } from "react";
import Datepicker, { DateValueType, DatepickerType } from "react-tailwindcss-datepicker";

type DatePickerRangeProps = Omit<DatepickerType, "value"> & {
  id?: string;
  label?: string;
  description?: string;
  error?: Error;
  defaultValue?: string;
  rootClassName?: string;
  className?: string;
  value?: DateValueType;
};

const DatePickerRange = ({ id, description, label, className, value: val, rootClassName, onChange, ...restProps }: DatePickerRangeProps) => {
  const [value, setValue] = useState<DateValueType>(
    val ?? {
      startDate: null,
      endDate: null,
    }
  );

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={clsx("mb-6", rootClassName)}>
      {label && (
        <label className="block text-sm font-normal text-slate-600 dark:text-slate-400" htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={clsx(
          "position-relative mt-1 flex w-full appearance-none flex-row items-center rounded rounded-md border border-slate-300 pl-3 pr-0 leading-tight text-slate-700 dark:border-slate-800 dark:bg-slate-800 dark:text-white sm:text-sm",
          className,
          {}
        )}
      >
        <Datepicker
          inputId={id}
          value={value}
          useRange={false}
          showShortcuts={true}
          primaryColor={"indigo"}
          onChange={handleValueChange}
          inputClassName="w-full border-0 border-none bg-transparent px-0 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm pr-10 text-slate-700 dark:bg-slate-800 dark:text-white sm:text-sm text-elipsis"
          {...restProps}
        />
      </div>
      {description && <div className="mt-2 text-sm text-slate-400 dark:text-slate-600">{description}</div>}
    </div>
  );
};

export default DatePickerRange;
