import { COLORS } from "@/types/app";
import Loader from "./loader";
import Highlights from "./highlights";

export const LoadingPlaceholder = ({
  loaderColor,
}: {
  loaderColor?: COLORS;
}) => (
  <div className="flex h-screen w-full items-center justify-center">
    <div className="  max-w-md -translate-y-1/3 p-10 text-center">
      <Loader color={loaderColor} />
      <h3 className="mt-6 text-sm font-normal uppercase text-slate-900 dark:text-white">
        Did you know
      </h3>
      <p className="mt-2 h-16 text-sm text-slate-600 line-clamp-3 dark:text-slate-400">
        <Highlights />
      </p>
    </div>
  </div>
);
