import { PropsWithChildren } from "react";
import UserInfoProvider from "@/contexts/userInfoContext";
import ToastProvider from "@/contexts/toastContext";

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <ToastProvider>
      <UserInfoProvider>{children}</UserInfoProvider>
    </ToastProvider>
  );
};

export default Providers;
