import {
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { ValidationResult } from "./inputTypes";
import { InputError } from "@/components/inputError";
import clsx from "clsx";

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
  id?: string;
  label?: string;
  description?: string;
  after?: ReactNode;
  before?: ReactNode;
  error?: Error;
  defaultValue?: string;
  debounce?: number;
  rootClassName?: string;
  classNameBefore?: string;
  classNameAfter?: string;
  filter?: (value: string) => string;
  validator?: (value: string) => ValidationResult;
  onChange: (value: string) => void;
  onError?: (error: Error) => void;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id: propId,
      label,
      debounce,
      description,
      defaultValue,
      after,
      before,
      error,
      className,
      rootClassName,
      classNameBefore,
      classNameAfter,
      filter,
      validator,
      onChange,
      onError,
      onBlur,
      onFocus,
      ...InputProps
    }: InputProps,
    ref
  ) => {
    const generatedId = useId();
    const id = useMemo(() => propId ?? generatedId, [propId, generatedId]);
    const [inputError, setInputError] = useState<Error | undefined>(undefined);
    const [inputValue, setInputValue] = useState<string>(defaultValue ?? "");
    const [isFocus, setIsFocus] = useState(false);
    const inputValueDebounced = useDebounce(inputValue, debounce ?? 200);

    const errorText = useMemo(
      () => inputError?.message || error?.message,
      [inputError, error]
    );

    const validate = useCallback(() => {
      const validationResult = validator?.(inputValueDebounced) ?? {
        valid: true,
      };

      if (!validationResult.valid) {
        setInputError(validationResult.error);
      } else {
        setInputError(undefined);
      }
    }, [inputValueDebounced, validator]);

    useEffect(() => {
      if (inputValueDebounced !== "") {
        validate();
      }
    }, [inputValueDebounced, validate]);

    useEffect(() => {
      if (inputError) {
        onError?.(inputError);
      }
    }, [onError, inputError]);

    useEffect(() => {
      setInputValue(defaultValue ?? "");
    }, [defaultValue]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const v = filter?.(event.target.value) ?? event.target.value;

      setInputError(undefined);
      onChange?.(v);
      setInputValue(v);
    };

    const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(event);
      setIsFocus(true);
    };

    const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      validate();

      setIsFocus(false);
      onBlur?.(event);
    };

    return (
      <div className={clsx("mb-6", rootClassName)}>
        {label && (
          <label
            className="block text-sm font-normal text-slate-600 dark:text-slate-400"
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div
          className={clsx(
            "mt-1 flex w-full appearance-none flex-row items-center rounded rounded-md border px-3 leading-tight text-gray-700 dark:bg-slate-800 dark:text-white sm:text-sm",
            className,
            {
              "border-transparent shadow-sm ring-2 ring-indigo-500 ring-offset-slate-900 dark:ring-offset-slate-900":
                isFocus,
            },
            { "border-slate-300 dark:border-slate-800": !errorText },
            { "border-red-500": errorText }
          )}
        >
          {before && <div className={classNameBefore}>{before}</div>}
          <input
            className="w-full border-0 border-none bg-transparent px-0 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm"
            id={id}
            defaultValue={defaultValue}
            value={inputValue}
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            ref={ref}
            {...InputProps}
          />
          {after && <div className={classNameAfter}>{after}</div>}
        </div>
        {description && !errorText && (
          <div className="mt-2 text-sm text-slate-400 dark:text-slate-600">
            {description}
          </div>
        )}
        {errorText && <InputError error={errorText} id={`${id}-error`} />}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
