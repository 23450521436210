import { BrowserRouter, Route, Routes } from "react-router-dom";
import Providers from "@/components/providers";
import DashboardPage from "@/routes/dashboard";
import Login from "@/routes/login";
import Logout from "@/routes/logout";
import AuthorizedPage from "@/components/authorizedPage";

function App() {
  return (
    <Providers>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthorizedPage>
                <DashboardPage />
              </AuthorizedPage>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/dashboard"
            element={
              <AuthorizedPage>
                <DashboardPage />
              </AuthorizedPage>
            }
          />
        </Routes>
      </BrowserRouter>
    </Providers>
  );
}

export default App;
