import React, { useEffect, useRef } from "react";

const steps = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

function makeArray(r: any) {
  return [].slice.call(r, 0);
}

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function stop(timer: ReturnType<typeof setTimeout>) {
  if (timer) {
    clearTimeout(timer);
  }
}

export default function Loader({ color = "slate" }) {
  const pixelWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const min: number = 0;
    const max: number = steps.length - 1;
    let timer: ReturnType<typeof setTimeout>;

    if (pixelWrapperRef.current) {
      let pixels: HTMLDivElement[] = makeArray(
        pixelWrapperRef.current.getElementsByClassName("pixel")
      );

      (function setColor() {
        for (let i = 0; i < pixels.length; i++) {
          pixels[i].className = `pixel bg-${color}-${
            steps[getRandomInt(min, max)]
          }`;
        }

        timer = setTimeout(function () {
          setColor();
        }, 1000);
      })();
    }

    return () => stop(timer);
  }, [color]);

  return (
    <div className="pixel-wrapper" ref={pixelWrapperRef}>
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
      <div className="pixel" />
    </div>
  );
}
