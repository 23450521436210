"use client";

import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

export const ShowHidePasswordIcon = ({
  onClick,
  isShown,
}: {
  onClick: () => void;
  isShown: boolean;
}) => {
  const Icon = isShown ? EyeSlashIcon : EyeIcon;

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      onClick={handleOnClick}
      type="button"
      className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none"
    >
      <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </button>
  );
};
