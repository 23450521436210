import { User, TIAMUser } from "@/types/app";

const headers = new Headers();

headers.append("Content-Type", "application/json");
headers.append("accept", "application/json");

const baseUrl = process.env.REACT_APP_REST_API;

export type PostUserLoginBody = { email: string; password: string };

// login
export async function postUserLogin(req: PostUserLoginBody) {
  try {
    const res = await fetch(`${baseUrl}user/login`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(req),
      headers,
    });
    if (res.ok) {
      return res.json();
    } else {
      throw await res.json();
    }
  } catch (e) {
    throw e;
  }
}

// logout
export async function getUserLogout() {
  try {
    const res = await fetch(`${baseUrl}user/logout`, {
      mode: "cors",
      credentials: "include",
      headers,
    });
    if (res.ok) {
      return res.json();
    } else {
      throw await res.json();
    }
  } catch (e) {
    throw e;
  }
}

// get user info
export async function getUserInfo(): Promise<User> {
  const res = await fetch(`${baseUrl}user/info`, {
    mode: "cors",
    credentials: "include",
    headers,
  });

  if (res.ok) {
    return res.json();
  } else {
    throw await res.json();
  }
}

export type TIAMUserRequest = {
  firstName?: string;
  lastName?: string;
  email?: string;
  verified?: string;
  subscribed?: string;
  created_at_from?: string;
  created_at_to?: string;
  subscription_expired_at_from?: string;
  subscription_expired_at_to?: string;
};

// loads list of IAM users
export async function getIAMUsers(
  requestParams: TIAMUserRequest
): Promise<TIAMUser[]> {
  const searchParams = new URLSearchParams({ ...requestParams });

  const res = await fetch(`${baseUrl}iam/user?${searchParams.toString()}`, {
    mode: "cors",
    credentials: "include",
    headers,
  });

  if (res.ok) {
    return res.json();
  } else {
    throw await res.json();
  }
}

// force verify user
export async function verifyIAMUser(
  userId: TIAMUser["id"]
): Promise<TIAMUser[]> {
  const res = await fetch(`${baseUrl}/iam/verify/${userId}`, {
    mode: "cors",
    method: "PUT",
    credentials: "include",
    headers,
  });

  if (res.ok) {
    return res.json();
  } else {
    throw await res.json();
  }
}
