import React from "react";

import DashboardHeader from "@/components/dashboardHeader";
import Dashboard from "@/components/dashboard/dashboard";

const DashboardPage = () => {
  return (
    <div className="max-w-8xl mx-auto w-full px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-8 lg:px-8">
      <main className="min-h-screen py-5 lg:col-span-12">
        {/* Start main area*/}
        <DashboardHeader />
        <Dashboard />
        {/* End main area */}
      </main>
    </div>
  );
};

export default DashboardPage;
