import { PropsWithChildren, useContext } from "react";
import { UserInfoContext } from "@/contexts/userInfoContext";
import { Navigate } from "react-router-dom";

const AuthorizedPage = ({ children }: PropsWithChildren) => {
  let { isSignedIn, userInfo: user } = useContext(UserInfoContext);

  return (
    <>
      {/* Very stupid solution */}
      {isSignedIn && user && user.email === "admin@koosh.studio" ? (
        children
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default AuthorizedPage;
