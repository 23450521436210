import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { getUserInfo } from "../api";
import { User } from "../types/app";

export type UserInfoContextType = {
  userInfo: User | null;
  userInfoLoading: boolean;
  userInfoError: Error | null;
  setUserInfo: React.Dispatch<React.SetStateAction<User | null>>;
  setUserInfoLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setUserInfoError: React.Dispatch<React.SetStateAction<Error | null>>;
  refetchUserInfo: () => Promise<void>;
  clearContext: () => void;
  userId: User["id"] | undefined;
  isSignedIn: boolean;
};

const defaultState: UserInfoContextType = {
  userInfo: null,
  userInfoLoading: true,
  userInfoError: null,
  setUserInfo: () => {},
  setUserInfoLoading: () => {},
  setUserInfoError: () => {},
  refetchUserInfo: async () => {},
  clearContext: () => {},
  isSignedIn: false,
  userId: undefined,
};

export const UserInfoContext = React.createContext(defaultState);

const UserInfoProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const refetchUserInfo = useCallback(async () => {
    try {
      const user = await getUserInfo();
      setUser(user);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    refetchUserInfo();
  }, [refetchUserInfo]);

  const clearContext = () => {
    setLoading(false);
    setError(null);
    setUser(null);
  };

  const state = useMemo<UserInfoContextType>(
    () => ({
      userInfo: user,
      userInfoLoading: loading,
      userInfoError: error,
      setUserInfo: setUser,
      setUserInfoLoading: setLoading,
      setUserInfoError: setError,
      refetchUserInfo,
      isSignedIn: !!user && !loading,
      userId: user?.id ?? undefined,
      clearContext,
    }),
    [error, loading, refetchUserInfo, user]
  );

  return (
    <UserInfoContext.Provider value={state}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoProvider;
