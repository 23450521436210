import clsx from "clsx";
import dayjs from "dayjs";

import DashboardTableFooter from "./dashboardTableFooter";
import DashboardTableHeader, {
  DashboardTableHeaderProps,
} from "./dashboardTableHeader";
import { TDirection, TIAMUser } from "../../types/app";
import Avatar from "../avatar";
import { Button } from "../button";
import { TIAMUserRequest } from "../../api";

const getStatusClass = (status: boolean) =>
  status ? "text-green-400 bg-green-400/10" : "text-rose-400 bg-rose-400/10";

type DashboardTableProps = {
  totalSubscribed: number;
  totalVerified: number;
  total: number;
  filteredPeople: TIAMUser[];
  selectedPeople: TIAMUser[];
  sortField: keyof TIAMUser;
  sortDirection: TDirection;
  onVerifyClicked: (id: string) => void;
  onSelectedPersonChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    person: TIAMUser
  ) => void;
  onToggleAll: () => void;
  onSortChange: (field: keyof TIAMUser, direction: TDirection) => void;
  onFilterUpdate: (filter: TIAMUserRequest) => void;
} & DashboardTableHeaderProps;

const DashboardTable = ({
  totalSubscribed,
  totalVerified,
  total,
  filteredPeople,
  selectedPeople,
  sortField,
  sortDirection,
  isIndeterminate,
  checked,
  onVerifyClicked,
  onSelectedPersonChange,
  onToggleAll,
  onSortChange,
  onFilterUpdate,
}: DashboardTableProps) => {
  return (
    <table className="min-w-full table-fixed divide-y divide-slate-300 dark:divide-slate-700">
      <DashboardTableHeader
        onToggleAll={onToggleAll}
        onSortChange={onSortChange}
        onFilterUpdate={onFilterUpdate}
        sortField={sortField}
        sortDirection={sortDirection}
        isIndeterminate={isIndeterminate}
        checked={checked}
      />

      <tbody className="divide-y divide-slate-200 bg-white dark:divide-slate-800 dark:bg-slate-900">
        {filteredPeople.map((person: TIAMUser, index: number) => (
          <tr
            key={index}
            className={
              selectedPeople.includes(person)
                ? "bg-slate-50 dark:bg-slate-800"
                : undefined
            }
          >
            <td className="relative px-7 sm:w-12 sm:px-6">
              {selectedPeople.includes(person) && (
                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600 dark:bg-indigo-500" />
              )}
              <input
                type="checkbox"
                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-600 dark:border-slate-700"
                value={person.email}
                checked={selectedPeople.includes(person)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onSelectedPersonChange(e, person)
                }
              />
            </td>
            <td
              className={clsx(
                "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                selectedPeople.includes(person)
                  ? "text-indigo-600"
                  : "text-slate-900 dark:text-white"
              )}
            >
              <div className="flex items-center gap-x-4">
                {person.picture ? (
                  <img
                    src={person.picture}
                    alt=""
                    className="h-8 w-8 rounded-full bg-slate-800"
                  />
                ) : (
                  <Avatar
                    size={8}
                    color={person?.color}
                    picture={person?.picture}
                  />
                )}
                <div className="truncate text-sm font-medium leading-6 text-slate-900 dark:text-white">
                  {person.firstName}
                </div>
              </div>
            </td>
            <td
              className={clsx(
                "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                selectedPeople.includes(person)
                  ? "text-indigo-600"
                  : "text-slate-900 dark:text-white"
              )}
            >
              <div className="flex items-center gap-x-4">
                <div className="truncate text-sm font-medium leading-6 text-slate-900 dark:text-white">
                  {person.lastName}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-600 dark:text-slate-400">
              <a
                className="text-indigo-600 underline dark:text-indigo-500"
                href={`mailto:${person.email}`}
              >
                {person.email}
              </a>
            </td>

            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-600 dark:text-slate-400">
              <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                <div
                  className={clsx(
                    getStatusClass(person.verified),
                    "flex-none rounded-full p-1"
                  )}
                >
                  <div className="h-1.5 w-1.5 rounded-full bg-current" />
                </div>
                <div className="hidden text-slate-900 dark:text-white sm:block">
                  {person.verified ? "Verified" : "Not verified"}
                </div>
                {!person.verified && (
                  <Button
                    onClick={() => onVerifyClicked(person.id)}
                    label="Verify"
                  />
                )}
              </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-600 dark:text-slate-400">
              <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                <div
                  className={clsx(
                    getStatusClass(person.subscribed),
                    "flex-none rounded-full p-1"
                  )}
                >
                  <div className="h-1.5 w-1.5 rounded-full bg-current" />
                </div>
                <div className="hidden text-slate-900 dark:text-white sm:block">
                  {person.subscribed ? "Subscribed" : "Not subscribed"}
                </div>
              </div>
            </td>

            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-600 dark:text-slate-400">
              {person.subscription_expired_at &&
                dayjs(person.subscription_expired_at).format(
                  "DD MMM YYYY hh:mm:ss"
                )}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-600 dark:text-slate-400">
              {person.created_at &&
                dayjs(person.created_at).format("DD MMM YYYY hh:mm:ss")}
            </td>
          </tr>
        ))}
      </tbody>

      <DashboardTableFooter
        totalSubscribed={totalSubscribed}
        totalVerified={totalVerified}
        total={total}
      />
    </table>
  );
};

export default DashboardTable;
