import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { downloadFile } from "../../utils/dowloadFile";
import { LoadingPlaceholder } from "../loadingPlaceholder";
import dayjs from "dayjs";
import { TDirection, TIAMUser } from "../../types/app";
import Papa from "papaparse";
import { useDebounce } from "../../hooks/useDebounce";
import { TIAMUserRequest, getIAMUsers, verifyIAMUser } from "../../api";
import DashboardTable from "./dashboardTable";

const filterFieldsToExport = (
  data: TIAMUser[]
): Pick<
  TIAMUser,
  | "firstName"
  | "lastName"
  | "email"
  | "subscribed"
  | "verified"
  | "subscription_expired_at"
  | "created_at"
>[] => {
  return data.map((person) => ({
    firstName: person.firstName,
    lastName: person.lastName,
    email: person.email,
    subscribed: person.subscribed,
    verified: person.verified,
    subscription_expired_at: person.subscription_expired_at
      ? dayjs(person.subscription_expired_at).format("DD MMM YYYY hh:mm:ss")
      : "",
    created_at: person.created_at
      ? dayjs(person.created_at).format("DD MMM YYYY hh:mm:ss")
      : "",
  }));
};

const Dashboard = () => {
  const [people, setPeople] = useState<TIAMUser[]>([]);
  const [filter, setFilter] = useState<TIAMUserRequest>({});
  const filterDebounced = useDebounce(filter, 200);

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<any>([]);
  const [sortDirection, setSortDirection] = useState<TDirection>("asc");
  const [sortField, setSortField] = useState<keyof TIAMUser>("firstName");
  const [totalVerified, setTotalVerified] = useState(0);
  const [totalSubscribed, setTotalSubscribed] = useState(0);
  const [total, setTotal] = useState(0);

  const filteredPeople = useMemo(() => {
    const filtered = people.filter((person) => {
      let matched = true;

      if (filterDebounced.firstName) {
        matched =
          matched &&
          (person.firstName
            .toLowerCase()
            .includes(filterDebounced.firstName.toLowerCase()) ||
            person.lastName
              .toLowerCase()
              .includes(filterDebounced.firstName.toLowerCase()));
      }

      if (filterDebounced.email) {
        matched =
          matched &&
          person.email
            .toLowerCase()
            .includes(filterDebounced.email.toLowerCase());
      }

      if (
        filterDebounced.subscribed !== undefined &&
        filterDebounced.subscribed !== ""
      ) {
        matched =
          matched &&
          person.subscribed ===
            Boolean(filterDebounced.subscribed === "true" ? true : false);
      }

      if (
        filterDebounced.verified !== undefined &&
        filterDebounced.verified !== ""
      ) {
        matched =
          matched &&
          person.verified ===
            Boolean(filterDebounced.verified === "true" ? true : false);
      }

      if (
        filterDebounced.subscription_expired_at_from &&
        filterDebounced.subscription_expired_at_to
      ) {
        matched =
          matched &&
          dayjs(person.subscription_expired_at).isAfter(
            dayjs(filterDebounced.subscription_expired_at_from)
          ) &&
          dayjs(person.subscription_expired_at).isBefore(
            dayjs(filterDebounced.subscription_expired_at_to)
          );
      }

      if (filterDebounced.created_at_from && filterDebounced.created_at_to) {
        matched =
          matched &&
          dayjs(person.created_at).isAfter(
            dayjs(filterDebounced.created_at_from)
          ) &&
          dayjs(person.created_at).isBefore(
            dayjs(filterDebounced.created_at_to)
          );
      }

      return matched;
    });

    if (sortField && sortDirection) {
      filtered.sort((a, b) => {
        if (sortDirection === "asc") {
          return a[sortField] > b[sortField] ? 1 : -1;
        }
        return a[sortField] < b[sortField] ? 1 : -1;
      });
    }

    return filtered;
  }, [people, sortDirection, sortField, filterDebounced]);

  const onSelectedPersonChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, person: TIAMUser) =>
      setSelectedPeople(
        e.target.checked
          ? [...selectedPeople, person]
          : selectedPeople.filter((p: TIAMUser) => p !== person)
      ),
    [selectedPeople]
  );

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < people.length;

    setChecked(selectedPeople.length === people.length);
    setIndeterminate(isIndeterminate);
  }, [selectedPeople, people.length]);

  const loadUsers = useCallback(async () => {
    setLoading(true);

    try {
      const peopleData = await getIAMUsers({});

      setPeople(peopleData);
    } catch (error) {
      // showToast({ title: "Error", subtitle: "Failed to load users" }, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    let verified = 0;
    let subscribed = 0;
    let totalAmount = 0;

    filteredPeople.forEach((person) => {
      if (person.subscribed) {
        subscribed++;
      }

      if (person.verified) {
        verified++;
      }

      totalAmount++;
    });

    setTotalVerified(verified);
    setTotalSubscribed(subscribed);
    setTotal(totalAmount);
  }, [filteredPeople]);

  const onToggleAll = useCallback(() => {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }, [checked, indeterminate, people]);

  const onSortChange = useCallback(
    (field: keyof TIAMUser, direction: TDirection) => {
      setSortField(field);
      setSortDirection(direction);
    },
    []
  );

  const onFilterUpdate = useCallback((filter: TIAMUserRequest) => {
    setFilter((prev) => ({ ...prev, ...filter }));
  }, []);

  const onVerifyClicked = useCallback(
    async (userId: TIAMUser["id"]) => {
      try {
        await verifyIAMUser(userId);
      } catch (error) {
        // showToast({ title: "Error", subtitle: "Failed to verify user" }, "error");
      }
      loadUsers();
    },
    [loadUsers]
  );

  const onDownloadSelectedClicked = useCallback(() => {
    const csv = Papa.unparse(filterFieldsToExport(selectedPeople));

    downloadFile("selected_users.csv", csv);
  }, [selectedPeople]);

  const onDownloadAllClicked = useCallback(() => {
    const csv = Papa.unparse(filterFieldsToExport(filteredPeople));

    downloadFile("all_users.csv", csv);
  }, [filteredPeople]);

  return (
    <div className="mt-8 flow-root min-h-screen min-w-full">
      <div className="-mx-4 -my-2 min-h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-h-full min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {loading && <LoadingPlaceholder />}

          {!loading && (
            <div className="relative min-h-screen">
              {selectedPeople.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-slate-900 dark:bg-slate-900 sm:left-12">
                  <button
                    onClick={onDownloadSelectedClicked}
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:bg-slate-900 dark:bg-slate-900 dark:text-white"
                  >
                    Export selected
                  </button>
                  <button
                    onClick={onDownloadAllClicked}
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:bg-slate-900 dark:bg-slate-900 dark:bg-slate-900 dark:text-white"
                  >
                    Export all
                  </button>
                </div>
              )}

              <DashboardTable
                onVerifyClicked={onVerifyClicked}
                onToggleAll={onToggleAll}
                onSortChange={onSortChange}
                onFilterUpdate={onFilterUpdate}
                onSelectedPersonChange={onSelectedPersonChange}
                sortField={sortField}
                sortDirection={sortDirection}
                filteredPeople={filteredPeople}
                selectedPeople={selectedPeople}
                total={total}
                totalSubscribed={totalSubscribed}
                totalVerified={totalVerified}
                isIndeterminate={indeterminate}
                checked={checked}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
